<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">{{ $t("Account") }}</h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                  />

                  <v-icon
                    :title="$t('Search')"
                    @click="getAllData(page)"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>

                <span title="Filter">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.types"
                          :label="$t('Type')"
                          :items="types"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->
                <v-icon
                  v-if="permissions.add"
                  large
                  @click="OpenDialogAddEdit"
                  class="main-color"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.subject="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.subject.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.subject }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.en.name="{ item }">
            <v-tooltip bottom max-width="1200px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.en.name.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.en.name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.ar.name="{ item }">
            <v-tooltip bottom max-width="1200px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.ar.name.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.ar.name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.notes="{ item }">
            <v-tooltip bottom max-width="1200px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.notes.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="permissions.edit"
              class="main-color"
              :id="item.id"
              @click="edit(item)"
              :title="$t('Edit')"
              >edit</v-icon
            >

            <v-icon
              v-if="permissions.delete"
              class="main-color"
              :id="item.id"
              @click="openDia(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>

    <AddEditAccountComponent
      :editedItem="editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' Account'"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.apiEndPoints"
    ></AddEditAccountComponent>

    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delet the Type ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editedItem)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AddEditAccountComponent from "../../components/modals/AddEditAccountComponent";
import axios from "axios";
import ACL from "../../acl";
export default {
  name: "Acounts",
  components: { AddEditAccountComponent },
  data() {
    return {
      options: {
        itemsPerPage: 15,
      },
      snack: "",
      types: [],
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Code"),
          value: "code",
          sortable: true,
        },
        {
          text: this.$i18n.t("Type Name"),
          value: "en.name",
          sortable: true,
        },
        {
          text: this.$i18n.t("Arabic Name"),
          value: "ar.name",
          sortable: true,
        },
        {
          text: this.$i18n.t("Type"),
          value: "type",
          sortable: true,
        },

        {
          text: this.$i18n.t("Notes"),
          value: "notes",
          sortable: true,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      title: this.$i18n.t("Accounts"),
      apiEndPoints: {
        list: "accounts",
        create: "accounts/store",
        edit: "accounts/update",
        delete: "accounts/destroy",
      },
      editedItem: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        subject_type_id: "",
        subject_type_name: "",
      },
      permissions: {
        list: ACL.checkPermission("list-account"),
        add: ACL.checkPermission("add-account"),
        edit: ACL.checkPermission("edit-account"),
        delete: ACL.checkPermission("delete-account"),
      },

      items: [],
      filter: {
        types: [],
      },
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      AddEditdialog: false,
      editedIndex: -1,
      confirmDialoag: false,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.$router.push(
        {
          path: "/allAccounts/types?page=" + this.page,
        },
        () => {}
      );
    },

    // search: {
    //   handler() {
    //     this.page = 1;
    //     this.getAllData(this.page);
    //   },
    //   deep: true,
    // },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    getTypes() {
      axios
        .get(
          this.getApiUrl + "/accounts/getTypes",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.types = response.data.data;
        });
    },
    onChildClick(value, item, index) {
      this.AddEditdialog = value;
      if (item != null && index == -1) {
        this.items.push(item);
        this.totalItems = this.totalItems + 1;

        this.length = Math.ceil(this.totalItems / this.options.itemsPerPage);
        if (this.page < this.length) {
          this.page = this.length;
        }
      } else if (index > -1)
        if (item != null) Object.assign(this.items[index], item);
      if (value == false) {
        this.items = [];
        this.$nextTick(function() {
          this.getAllData();
        });
      }
    },
    edit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.formTitle = "Edit";
      this.AddEditdialog = true;
    },
    openDia(item) {
      this.dialogReset = true;
      this.editedItem = item;
    },
    deleteItem(item) {
      console.log(item);

      axios
        .get(this.getApiUrl + "/accounts/destroy/" + this.editedItem.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.dialogReset = false;
          console.log(response.data);
          this.snack = true;
          this.snackColor = "green";
          this.snackText = response.data.status.message;
          this.getAllData();
        });
    },
    OpenDialogAddEdit() {
      this.editedIndex = -1;
      // this.table.editedItem = {};
      if (this.editedItem.en) {
        Object.keys(this.editedItem.en).forEach(
          (k) => (this.editedItem.en[k] = "")
        );
        Object.keys(this.editedItem).forEach((k) => {
          if (k != "en" && k != "ar") this.editedItem[k] = "";
        });
      } else {
        Object.keys(this.editedItem).forEach((k) => (this.editedItem[k] = ""));
      }
      if (this.editedItem.ar) {
        Object.keys(this.editedItem.ar).forEach(
          (k) => (this.editedItem.ar[k] = "")
        );
      }
      this.formTitle = "Add";
      this.AddEditdialog = true;
    },

    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl + "/accounts?search=" + this.search + "&page=" + page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    this.getTypes();
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
